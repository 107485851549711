"use client"

import type { ComponentProps, ReactNode } from "react"
import clsx from "clsx"
import {
  MARK_ANCHOR,
  MARK_ITALIC,
  MARK_LINK,
  MARK_TEXT_STYLE,
  NODE_LI,
  NODE_PARAGRAPH,
  type RenderOptions,
} from "storyblok-rich-text-react-renderer"

import { useLocale } from "~/lib/i18n/hooks/useLocale"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"
import { Image } from "~/components/ui/Image"
import { Link } from "~/components/ui/Link"
import { linkSchema } from "~/components/ui/Link/_data/schema"
import serializeLink from "~/components/ui/Link/_data/serializer"
import AbstractRichText, { type RichTextProps as AbstractRichTextProps } from "~/components/abstracts/RichText"

import * as css from "./styles.css"

export interface RichTextProps extends Omit<AbstractRichTextProps, "options"> {
  nodeResolverOptions?: RenderOptions["nodeResolvers"]
  anchorProps?: Omit<ComponentProps<"span">, "id">
}

function RichText({ className, as, style, render, nodeResolverOptions, anchorProps }: RichTextProps) {
  const locale = useLocale()
  return (
    <AbstractRichText
      className={clsx(css.RichText, className)}
      data-comp="UI/RichText"
      as={as}
      style={style}
      render={render}
      options={{
        blokResolvers: {
          ["image"]: (props) => {
            const REGEX = /\/([0-9]+)x([0-9]+)\//
            const result = REGEX.exec(props?.image?.filename) || []
            const [, width, height] = result

            return (
              <Image
                className={css.Image}
                {...props}
                src={props?.image?.filename ?? null}
                alt={props?.image?.alt ?? null}
                width={width}
                height={height}
                asPlaceholder
              />
            )
          },
          ["size_tag"]: (props) => {
            return <span className={css.sizeTag}>{props.label}</span>
          },
        },

        nodeResolvers: {
          [NODE_PARAGRAPH]: (children: ReactNode) => {
            const result = children?.toString() !== undefined ? <p>{children}</p> : <br /> ?? children
            return result
          },
          ...(nodeResolverOptions ?? {}),

          [NODE_LI]: (children: ReactNode) => {
            return <li className={css.list}>{children}</li>
          },
        },

        markResolvers: {
          [MARK_LINK]: (children, props) => {
            /*
              if additional styles are applied to a link (like bold or italic)
              then the link will be rendered as '[Object Object]'
              to avoid that behavior, make sure the children property is of type string
             */

            const preparedLink =
              "story" in props
                ? props
                : { url: props.href, target: props.target, linktype: "url", fieldtype: "multilink" }

            const link = handleZodSafeParse(linkSchema, preparedLink, {
              errorContext: "RichText Link",
            })
            const serializedLink = link ? serializeLink(link, locale) : {}
            return (
              <Link {...serializedLink}>
                {/* render the children nodes if there are any */}
                {children}
              </Link>
            )
          },
          [MARK_ANCHOR]: (children, props) => {
            return (
              <span {...props} {...anchorProps}>
                {children}
              </span>
            )
          },
          [MARK_TEXT_STYLE]: (children) => {
            //when the customer copy paste a text with a textColor
            //that remove the style rgb(x, x, x)
            return <>{children}</>
          },
          [MARK_ITALIC]: (children) => {
            return <i className={css.italic}>{children}</i>
          },
        },
      }}
    />
  )
}

export type { StoryblokRichtext as RichTextBlock } from "storyblok-rich-text-react-renderer"
export default RichText
